/********** Add Your Global CSS Here **********/

body {
    -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
    -webkit-padding-start: 0px;
    list-style: none;
    margin-bottom: 0;
}

.loading-wrapper {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
    width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
    height: 5px !important;
}

.scrollbar-thumb {
    border-radius: 0 !important;
}

.scrollbar-track {
    background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
    border-radius: 0 !important;
    background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft,
> .ant-popover-content,
> .ant-popover-arrow:after,
.ant-popover-placement-bottomRight,
> .ant-popover-content,
> .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft,
> .ant-popover-content,
> .ant-popover-arrow:after,
.ant-popover-placement-topRight,
> .ant-popover-content,
> .ant-popover-arrow:after {
    left: 0;
    margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
    text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
    text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
    left: 0;
    right: auto;
}

html[dir='rtl'] .ant-modal-close {
    right: inherit;
    left: 0;
}

html[dir='rtl'] .ant-modal-footer {
    text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
    margin-left: 0;
    margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
    margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
    margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
    margin-left: 0;
    margin-right: 0.5em;
}

html[dir='rtl'],
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
    padding-left: 25px;
    padding-right: 29px;
}

html[dir='rtl'],
.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline),
.anticon {
    margin-right: -14px;
    margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
    margin-left: 16px;
    margin-right: 0;
    float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
    float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
    margin-right: 10px;
    margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
    margin-left: 8px;
    margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
    padding-right: 20px;
    padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
    text-align: left;
}

/* Notification */

html[dir='rtl'],
.ant-notification-notice-closable,
.ant-notification-notice-message {
    padding-left: 24px;
    padding-right: 0;
}

html[dir='rtl'],
.ant-notification-notice-with-icon,
.ant-notification-notice-message,
html[dir='rtl'],
.ant-notification-notice-with-icon,
.ant-notification-notice-description {
}

html[dir='rtl'] .ant-notification-notice-close {
    right: auto;
    left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
    left: 0;
}

/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
    display: none;
}

.modal-size-wrapper img {
    max-width: 100%;
}

.mobile-filter-drawer {

}

.mobile-filter-drawer .ant-drawer-content {
    overflow-y: scroll;
    height: 80vh !important;
    padding-bottom: 50px;
}

.mobile-filter-drawer .ant-drawer-content .ant-drawer-header {
    position: fixed;
    background: white;
    width: 100%;
    z-index: 2;
}

.mobile-filter-drawer .ant-drawer-content .ant-drawer-body {
    padding-top: 75px;
}

body #item_0 Menu {
    background-color: red;
}

body .ant-menu-submenu-popup .ant-menu .ant-menu-item {
    background-color: transparent;
    color: #222;
}

body .ant-menu-submenu-popup .ant-menu .ant-menu-item a {
    color: #222;
}

body .ant-menu-submenu-popup .ant-menu .ant-menu-item-active {
    background-color: #ddd;
}

body .ant-menu-submenu-popup .ant-menu .ant-menu-item-active a {
    color: #222;
}

body .ant-menu-submenu-popup .ant-menu .ant-menu-item-active a:hover {
    color: #222;
}

body .ant-menu-submenu-popup .ant-menu .ant-menu-item-selected a {
    color: #222;
}

.sidelogin .ant-drawer-content-wrapper {
    max-width: 100%;
}

.sidelogin-closed {
    display: none !important;
}

.slick-prev, .slick-next {
    height: 40px !important;
    width: 25px !important;
}

.slick-prev:before, .slick-next:before {
    color: #000 !important;
    font-size: 30px !important;
}

.slick-prev {
    left: -35px !important;
}

.slick-next {
    right: -35px !important;
}

.slick-next:before {
    content: "\02C3"!important;
}

.slick-prev:before {
    content: "\02C2"!important;
}

.slick-slider {
    margin-left: 20px;
    margin-right: 20px;
}

.app-wrapper.sticky .store-notice {
    display: none;
}

/* review feature */
.review-modal .ant-modal-footer {
    display: none;
}

.review-modal {
    width: 75% !important;
    background: rgb(247, 247, 247);
    padding-bottom: 0 !important;
}

.review-modal img {
    text-align: center;
    object-fit: contain;
    max-height: 500px;
    width: 100%;
    height: 100%;
    padding: 25px;
}

.review-modal .review-modal-wrapper {
    min-height: 500px;
}

.review-modal-column {
    float: left;
    width: 50%;
}

.review-modal-row:after {
    content: "";
    display: table;
    clear: both;
}

.review-modal-left {
    width: 70%;
}

.review-modal-right {
    width: 30%;
}

.review-modal .review-title {
    font-size: 28px;
    line-height: 1.2em;
    color: #000;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 10px;
    padding-bottom: 15px;
}

.review-modal .review-modal-content {
    padding: 30px;
}

.review-modal .review-text {
    padding-bottom: 20px;
    overflow-y: scroll;
    position: relative;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0;
    text-transform: none;
    color: #000;
    font-weight: 300;
}

.review-modal .ant-modal-body {
    padding: 0 !important;
}

.review-modal .review-top-bar {
    display: flex;
    margin-bottom: 20px;
}

.review-modal .ant-rate-star-full svg {
    fill: #C09566;
}

.review-modal .review-date {
    padding-bottom: 0;
    margin-bottom: 0;
    vertical-align: baseline;
    height: 30px;
    line-height: 30px;
    padding-left: 30px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.review-modal .reviewer-info {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    -ms-column-count: 2;
    -o-column-count: 2;
    column-count: 2;
    margin: 10px 0 20px;
    border: 1px solid #DDDDDD;
    border-width: 1px 0;
    padding: 9px 0;
}

.review-modal .reviewer-info > li {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
    line-height: 18px;
}

.review-modal .reviewer-info li strong {
    min-width: 80px;
    float: right;
    color: #C09566;
    font-weight: 500;
    line-height: 18px;
}

.review-modal .moment-nav-prev {
    position: absolute;
    left: -50px;
    height: 100%;
    width: 50px;
    top: 0;
    cursor: pointer;
}

.review-modal .moment-nav-next {
    position: absolute;
    right: -50px;
    height: 100%;
    width: 50px;
    top: 0;
    cursor: pointer;
}

.review-modal .moment-nav-prev .arrow {
    width: 48px;
    height: 48px;
    border-top: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
    border-left: 0;
    border-bottom: 0;
    opacity: 1;
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    transform: rotate(225deg);
    position: absolute;
    top: calc(50% - 24px);
    left: 0;
}

.review-modal .moment-nav-next .arrow {
    width: 48px;
    height: 48px;
    border-top: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
    border-left: 0;
    border-bottom: 0;
    opacity: 1;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 24px);
    right: 0;
}

.review-modal .arrow:hover {
    opacity: 0.6;
}

@media only screen and (max-width: 640px) {
    .review-modal-column {
        width: 100%;
    }

    .review-modal .reviewer-info {
        column-count: 1;
    }

    .review-modal .review-modal-content {
        padding: 0 30px 30px;
    }

    .review-modal .moment-nav-prev .arrow {
        width: 24px;
        height: 24px;
        left: 24px;
    }

    .review-modal .moment-nav-next .arrow {
        width: 24px;
        height: 24px;
        right: 24px;
    }

    .review-modal .review-title {
        margin-bottom: 0;
    }
}

/* FOOTER START */
.footer-main {
	background: #F7F7F7;
	border-top: 1px solid #DDDDDD;
}
.footer-main.desktopview {
    padding: 100px;
    margin-bottom: 2em;
}
.footer-main.mobileview {
    padding: 15px;
    margin-bottom: 1em;
}
.footer-main.desktopview .footer-col {
    padding: 30px;
}
.footer-main.mobileview .footer-col {
    padding: 15px;
    width: 100%;
}
.footer-main.desktopview .footer-col .logo-width {
    width: auto;
}
.footer-main.mobileview .footer-col .logo-width {
    width: 100%;
}
.footer-main .secure-logo {
    display: flex;
    justify-content: center;
    height: 50px;
    background-color: #F7F7F7;
    margin-top: 20px;
}
.footer-main .footer-col h5 {
    text-transform: uppercase;
    margin-bottom: 2em;
    font-weight: 600;
    font-size: 13px;
}
.footer-main .footer-col ul li {
    padding-bottom: 2px;
}
.footer-main .footer-col ul li a {
    color: rgb(0,0,0);
}
.footer-main .footer-col ul li a :hover {
    color: #1890ff;
}
.footer-main .footer-col:last-child {
    margin-bottom: 4em;
}
.footer-main .footer-col:last-child p {
    margin-top: 0.5em;
    color: rgb(0,0,0);
}
.footer-main .footer-col:first-child .ant-card {
    background: transparent;
    border: 0;
}
.footer-main .footer-col:first-child .ant-card .ant-card-cover img {
    width: auto;
}
.footer-main .footer-col:first-child .ant-card .ant-card-body {
    padding: 24px 0;
}
.footer-main .footer-col:first-child .ant-card .ant-card-body .ant-card-meta-description {
    color: rgb(0,0,0);
}
/* FOOTER END */
.isomorphicContent.css-client {
    flex-shrink: 0;
    background: #ffffff;
    position: relative;
}
.isomorphicContent.css-client.mobileview {
    padding: 50px 0 0;
}
.isomorphicContent.css-client.desktopview {
    padding: 100px 0 0;
}